import React from 'react';
import Button from '../components/Button';
import Card from '../components/Card';
import CustomerCard from '../components/CustomerCard';
import LabelText from '../components/LabelText';
import Layout from '../components/layout/Layout';
import SplitSection from '../components/SplitSection';
import StatsBox from '../components/StatsBox';
import customerData from '../data/customer-data';
import HeroImage from '../svg/HeroImage';

import OverviewMap from "../components/Map";

/* see https://stackoverflow.com/questions/40365440/react-leaflet-map-not-correctly-displayed */
import 'leaflet/dist/leaflet.css';

const Index = () => (
  <Layout> 
    <section id="uebersicht" className="pt-20 md:pt-20">
      <div className="container mx-auto px-8 lg:flex">
        <div className="text-center lg:text-left lg:w-1/2 py-5">
          <h1 className="text-4xl lg:text-5xl xl:text-6xl font-bold leading-none">
            Baselbieter Naturschutztag
          </h1>
          <p className="text-xl lg:text-2xl mt-6 font-light">
            Am 26. Oktober 2024 &ndash; mancherorts auch früher oder später &ndash; finden in vielen Baselbieter Gemeinden Einsätze zugunsten der Natur statt.
          </p>
          <p className="text-xl lg:text-2xl mt-6 font-light">
            Melden Sie bitte Ihren Anlass mit untenstehendem Formular. Wir werden auf dieser Seite alle gemeldeten Anlässe zusammen mit einer Übersichtskarte publizieren. Die Publikation erfolgt nicht automatisch, sondern nach manueller Prüfung.
          </p>
           {/* <p className="text-xl lg:text-2xl mt-6 font-light">
            Finden Sie in der untenstehenden Übersicht einen Anlass in Ihrer Nähe und packen Sie mit an!
          </p>        */}
        </div>
        <div className="text-center lg:text-left lg:w-1/2 px-5">
          <img className="w-full rounded border-2" src="Heckenpflanzung.jpg"/>
        </div>        
      </div>
    </section>

     {/* <section id="karte" className="pt-20 md:pt-20">
      <div className="container mx-auto text-center">
      <OverviewMap />
      </div>
    </section> */}

{/* Beispiel: https://stackoverflow.com/questions/67417275/cards-of-same-height-in-tailwind-css */}
{/* Manual: https://tailwindcss.com/docs/grid-template-columns */}    

<section id="anlaesse" className="pt-20 md:pt-20">
      <div className="container mx-auto text-center">
<h2 className="text-3xl lg:text-5xl font-semibold mb-12">Gemeldete Anlässe</h2>
        <p className="text-xl lg:text-2xl mt-6 font-light">
        Die gemeldeten Anlässe werden in Kürze aufgeschaltet.
        </p>
{/*         <p className="text-xl lg:text-2xl mt-6 font-light mb-12">
        Das nächste Update der gemeldeten Anlässe erfolgt am 18. September 2023.
        </p>  */}    
        <div className="container grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">

{/*<Card className="" id="aesch-pfeffingen">
                     <p className="font-semibold text-xl">Aesch-Pfeffingen</p><p className="mt-4">28. Oktober 2023, 09.00 - 13.00 Uhr</p><p className="mt-0 mb-4">Kreuzung Butthollenweg / Klusweg in Aesch</p><p className="mt-2 text-xs text-left">Kontakt: Patrick Schaub, <a href="tel:077 434 16 56">077 434 16 56</a>, <a href="mailto:pschaub@vtxmail.ch">pschaub@vtxmail.ch</a></p><p className="mt-2 text-xs text-left">Veranstalter: Natur- und Vogelschutzverein Aesch-Pfeffingen</p></Card>
<Card className="" id="binningen">
                     <p className="font-semibold text-xl">Binningen</p><p className="mt-4">21. Oktober 2023, 09.00 - 12.00 Uhr</p><p className="mt-0 mb-4">Naturschutzgebiet Herzogenmatt, beim Gerätehaus</p><p className="mt-2 text-xs text-left">Kontakt: Benedikt Schmidt, <a href="tel:078 719 69 16">078 719 69 16</a>, <a href="mailto:benedikt.schmidt@ieu.uzh.ch">benedikt.schmidt@ieu.uzh.ch</a></p><p className="mt-2 text-xs text-left">Veranstalter: Verein Freunde der Herzogenmatt</p><p className="mt-2 text-xs text-left">Weitere Beteiligte: Verein Freunde der Herzogenmatt</p><p className="mt-2 text-xs text-left">Arbeiten: Diverse Pflegearbeiten</p><p className="mt-2 text-xs text-left">Bemerkungen: <a href="https://www.herzogenmatt.ch/arbeitseinsaetze?id=163">https://www.herzogenmatt.ch/arbeitseinsaetze?id=163</a></p></Card>
<Card className="" id="binningen">
                     <p className="font-semibold text-xl">Binningen</p><p className="mt-4">24. Juni 2023, 09.00 - 12.00 Uhr</p><p className="mt-0 mb-4">Dorfplatz, 4102 Binningen</p><p className="mt-2 text-xs text-left">Kontakt: Daniel Egli, <a href="tel:061 425 53 18">061 425 53 18</a>, <a href="mailto:daniel.egli@binningen.bl.ch">daniel.egli@binningen.bl.ch</a></p><p className="mt-2 text-xs text-left">Veranstalter: region Leimental Plus</p><p className="mt-2 text-xs text-left">Weitere Beteiligte: angefragte Naturschutzorgansiationen</p><p className="mt-2 text-xs text-left">Arbeiten: Bekämpfung invasive Neophyten</p><p className="mt-2 text-xs text-left">Bemerkungen: jährlicher Anlass</p></Card>
<Card className="" id="birsfelden">
                     <p className="font-semibold text-xl">Birsfelden</p><p className="mt-4">28. Oktober 2023, 09.00 - 12.00 Uhr</p><p className="mt-0 mb-4">Beim Froschbrunnen, "Biotop Am Stausee"</p><p className="mt-2 text-xs text-left">Kontakt: Judith Roth, <a href="tel:076 211 36 88">076 211 36 88</a>, <a href="mailto:judith@bitop-birsfelden.ch">judith@bitop-birsfelden.ch</a></p><p className="mt-2 text-xs text-left">Veranstalter: Natur- und Vogelschutzverein Birsfelden</p><p className="mt-2 text-xs text-left">Weitere Beteiligte: Werkhof (Vorarbeiten)</p><p className="mt-2 text-xs text-left">Arbeiten: 40 Jahre Biotop am Stausee. Wir pflegen das 1 Hektar grosse und vielfältige Lebensraummosaik.</p><p className="mt-2 text-xs text-left">Bemerkungen: <strong>Anmeldung</strong> bis zum Donnerstag, 26. Oktober 2023, damit wir die Verpflegung besser organisieren können, unter <a href="https://biotop-birsfelden.ch/anmeldung-anlass">https://biotop-birsfelden.ch/anmeldung-anlass</a></p></Card>
<Card className="" id="blauen">
                     <p className="font-semibold text-xl">Blauen</p><p className="mt-4">28. Oktober 2023, 08.30 - 15.00 Uhr</p><p className="mt-0 mb-4">Schützenhaus Blauen, Kirchägerten 3, 4223 Blauen</p><p className="mt-2 text-xs text-left">Kontakt: Stefan Jeisy, <a href="tel:079 475 48 12">079 475 48 12</a>, <a href="mailto:stefan.jeisy@gmx.ch">stefan.jeisy@gmx.ch</a></p><p className="mt-2 text-xs text-left">Veranstalter: Burgerkorporation</p><p className="mt-2 text-xs text-left">Weitere Beteiligte: Forst, Jagd, Natur und Vogelschutz, Schützenverein, Dorfbevölkerung</p><p className="mt-2 text-xs text-left">Arbeiten: Hegen und Pflegen der Blauner-Weide, Säuberung der Mäharbeiten.</p><p className="mt-2 text-xs text-left">Bemerkungen: Mittagessen ist offeriert</p></Card>
<Card className="" id="bretzwil">
                     <p className="font-semibold text-xl">Bretzwil</p><p className="mt-4">21. Oktober 2023, 08.30 - 12.00 Uhr</p><p className="mt-0 mb-4">Naturerlebnispfad im Gebiet Schären</p><p className="mt-2 text-xs text-left">Kontakt: Karin Mühlberg, <a href="tel:079 416 88 65">079 416 88 65</a>, <a href="mailto:k.muehlbi@bluewin.ch">k.muehlbi@bluewin.ch</a></p><p className="mt-2 text-xs text-left">Veranstalter: Umweltkommission Bretzwil, Natur- und Vogelschutzverein Bretzwil</p><p className="mt-2 text-xs text-left">Arbeiten: Aufschichten von Asthaufen / Aufwerten des Lebensraums für kleine Säugetiere</p><p className="mt-2 text-xs text-left">Bemerkungen: Im Anschluss ist für das leibliche Wohl gesorgt.</p></Card>
<Card className="" id="bubendorf">
                     <p className="font-semibold text-xl">Bubendorf</p><p className="mt-4">28. Oktober 2023, 08.30 - 12.00 Uhr</p><p className="mt-0 mb-4">Parkplatz Vita-Parcours Bubendorf (an der Strasse auf den Murenberg)</p><p className="mt-2 text-xs text-left">Kontakt: Thomas Aebischer, <a href="tel:999 999 99 99">999 999 99 99</a>, <a href="mailto:info@nvvb.ch">info@nvvb.ch</a></p><p className="mt-2 text-xs text-left">Veranstalter: Natur- und Vogelschutzverein Bubendorf</p><p className="mt-2 text-xs text-left">Arbeiten: Heckenpflege, Pflege von jungen Hochstammobstbäumen, Unterhalt Baumgarten</p><p className="mt-2 text-xs text-left">Bemerkungen: Zum Abschluss gemeinsames Mittagessen</p></Card>
<Card className="" id="buus">
                     <p className="font-semibold text-xl">Buus</p><p className="mt-4">28. Oktober 2023, 09.00 - 16.00 Uhr</p><p className="mt-0 mb-4">Dorfplatz</p><p className="mt-2 text-xs text-left">Kontakt: Markus Ritter, <a href="tel:079 408 16 03">079 408 16 03</a>, <a href="mailto:markus.ritter@bl.ch">markus.ritter@bl.ch</a></p><p className="mt-2 text-xs text-left">Veranstalter: NVB Buus und Einwohnergemeinde</p><p className="mt-2 text-xs text-left">Arbeiten: Allee Pflanzen</p></Card>
<Card className="" id="dittingen">
                     p className="font-semibold text-xl">Dittingen</p><p className="mt-4">28. Oktober 2023, 13.15 - 18.30 Uhr</p><p className="mt-0 mb-4">Schulhausplatz Dittingen, Schulweg 2, 4243 Dittingen; 47.442463, 7.496787</p><p className="mt-2 text-xs text-left">Kontakt: Céline Jermann, <a href="tel:079 850 30 29">079 850 30 29</a>, <a href="mailto:verwaltung@bk-dittingen.ch">verwaltung@bk-dittingen.ch</a></p><p className="mt-2 text-xs text-left">Veranstalter: Burgerkorporation Dittingen</p><p className="mt-2 text-xs text-left">Arbeiten: Wir feiern Jubiläum - den 40. Dittinger Naturschutztag!

Für einmal soll nicht gearbeitet und geschwitzt werden.. Es sollten auch keine Blasen an den Händen zurückbleiben😉
Wir werden Rückschau halten, feiern und nicht zuletzt auch sehen, welche Projekte uns in Zukunft erwarten.

Wir bitten um <strong>Anmeldung</strong> vorab bis am Mittwoch, 18. Oktober 2023 unter: <a href="https://forms.office.com/e/uSu6TUNXd6">https://forms.office.com/e/uSu6TUNXd6</a>

und freuen uns mit Dir zu feiern😊</p></Card>
<Card className="" id="ettingen">
                     <p className="font-semibold text-xl">Ettingen</p><p className="mt-4">28. Oktober 2023, 13.30 - 16.00 Uhr</p><p className="mt-0 mb-4">beim Forsthaus, Hofstettenstrasse 30, 4107 Ettingen</p><p className="mt-2 text-xs text-left">Kontakt: Peter Brodmann-Gross, <a href="tel:079 716 64 07">079 716 64 07</a>, <a href="mailto:peter.brodmann@bluewin.ch">peter.brodmann@bluewin.ch</a></p><p className="mt-2 text-xs text-left">Veranstalter: Naturschutzverein Ettingen (NSVE)</p><p className="mt-2 text-xs text-left">Weitere Beteiligte: Forst, Blauen-Biker, Jungschar Ettingen-Therwil, BG Ettingen</p><p className="mt-2 text-xs text-left">Arbeiten: Im Naturschutzgebiet «Stapflenreben» werden durch Forstmitarbeiter geschnittene Äste zerkleinert, zusammengetragen und auf Haufen geschichtet. Nistkasten-Kontrolle/-Reinigung</p><p className="mt-2 text-xs text-left">Bemerkungen: im Anschluss Zvieri für alle Teilnehmenden</p></Card>
<Card className="" id="füllinsdorf">
                     <p className="font-semibold text-xl">Füllinsdorf</p><p className="mt-4">28. Oktober 2023, 09.00 - 12.00 Uhr</p><p className="mt-0 mb-4">Banntagsplatz Giebenacher Höhe</p><p className="mt-2 text-xs text-left">Kontakt: Dominik Lüscher, <a href="tel:079 209 52 06">079 209 52 06</a>, <a href="mailto:dluescher@gmx.li">dluescher@gmx.li</a></p><p className="mt-2 text-xs text-left">Veranstalter: Natur- und Vogelschutzverein und Naturschutzkommission</p><p className="mt-2 text-xs text-left">Arbeiten: Heckenpflege</p></Card>
<Card className="" id="häfelfingen">
                     <p className="font-semibold text-xl">Häfelfingen</p><p className="mt-4">4. November 2023, 09.00 - 16.00 Uhr</p><p className="mt-0 mb-4">Turnplatz Häfelfingen</p><p className="mt-2 text-xs text-left">Kontakt: Remi Wüthrich, <a href="tel:079 704 41 61">079 704 41 61</a>, <a href="mailto:re.wuethrich@gmx.ch">re.wuethrich@gmx.ch</a></p><p className="mt-2 text-xs text-left">Veranstalter: Einwohnergemeinde / Gemeinderat  Häfelfingen</p><p className="mt-2 text-xs text-left">Weitere Beteiligte: Gemeinderat Häfelfingen</p><p className="mt-2 text-xs text-left">Arbeiten: Diverse Naturpflegearbeiten</p></Card>
<Card className="" id="hemmiken">
                     <p className="font-semibold text-xl">Hemmiken</p><p className="mt-4">28. Oktober 2023, 08.30 - 16.30 Uhr</p><p className="mt-0 mb-4">Dorfplatz</p><p className="mt-2 text-xs text-left">Kontakt: Alfred Sutter, <a href="tel:079 211 28 25">079 211 28 25</a>, <a href="mailto:info@hemmiken.ch">info@hemmiken.ch</a></p><p className="mt-2 text-xs text-left">Veranstalter: Natur- und Vogelschutzverein / Bürgergemeinde</p><p className="mt-2 text-xs text-left">Weitere Beteiligte: Einwohnergemeinde Hemmiken</p></Card>
<Card className="" id="itingen">
                     <p className="font-semibold text-xl">Itingen</p><p className="mt-4">NA, 09.00 - 12.30 Uhr</p><p className="mt-0 mb-4">Werkhof, Brüschigasse, Itingen</p><p className="mt-2 text-xs text-left">Kontakt: Rahel plattner, <a href="tel:061 922 20 93">061 922 20 93</a>, <a href="mailto:rahel@plattnerweb.ch">rahel@plattnerweb.ch</a></p><p className="mt-2 text-xs text-left">Veranstalter: Umwelt- und Energiekommission, Natur- und Vogelschutzverein</p><p className="mt-2 text-xs text-left">Weitere Beteiligte: UEK, Werkhof, NVI</p><p className="mt-2 text-xs text-left">Arbeiten: Arbeiten am Weiher und verschiedenen Pflegeeinsätze</p><p className="mt-2 text-xs text-left">Bemerkungen: Anschliessend feines Mittagessen :-)</p></Card>
<Card className="" id="lampenberg">
                     <p className="font-semibold text-xl">Lampenberg</p><p className="mt-4">21. Oktober 2023, 08.30 - 12.00 Uhr</p><p className="mt-0 mb-4">Hauptstrasse 40, Gemeindeverwaltung</p><p className="mt-2 text-xs text-left">Kontakt: Regine Ruff, <a href="tel:061 931 37 68">061 931 37 68</a>, <a href="mailto:regine.ruff@lampenberg.ch">regine.ruff@lampenberg.ch</a></p><p className="mt-2 text-xs text-left">Veranstalter: Einwohnergemeinde</p><p className="mt-2 text-xs text-left">Arbeiten: Kleine Hecke pflanzen, Aufräumarbeiten im Wald</p></Card>
<Card className="" id="läufelfingen">
                     <p className="font-semibold text-xl">Läufelfingen</p><p className="mt-4">22. Oktober 2023, 08.30 - 13.00 Uhr</p><p className="mt-0 mb-4">Werkhof beim Bahnhof Läufelfingen</p><p className="mt-2 text-xs text-left">Kontakt: Christina Spühler, <a href="tel:079 892 44 14">079 892 44 14</a>, <a href="mailto:martins7@bluewin.ch">martins7@bluewin.ch</a></p><p className="mt-2 text-xs text-left">Veranstalter: Natur-und Vogelschutz</p><p className="mt-2 text-xs text-left">Arbeiten: Zusammen mit Forst/Jägern/ Konfirmanden und Privaten sind diverse Arbeiten geplant: pflege Waldränder/mähen und rechen Magerwiesen/Asthaufen schichten u a</p></Card>
<Card className="" id="lausen">
                     <p className="font-semibold text-xl">Lausen</p><p className="mt-4">21. Oktober 2023, 08.30 - 13.30 Uhr</p><p className="mt-0 mb-4">Werkhof Stutz Lausen</p><p className="mt-2 text-xs text-left">Kontakt: Stefan Crollet, <a href="tel:076 424 65 60">076 424 65 60</a>, <a href="mailto:stef.crollet@nvl.ch">stef.crollet@nvl.ch</a></p><p className="mt-2 text-xs text-left">Veranstalter: Naturschutzverein Lausen</p><p className="mt-2 text-xs text-left">Weitere Beteiligte: Gemeinde, Fischerei, Verkehrs- und Verschönerungsverein</p><p className="mt-2 text-xs text-left">Arbeiten: mähen, Sträucher zurückschneiden, Hänge säubern von Ästen und Steinen, Sträucher pflanzen, Nistkasten reinigen</p><p className="mt-2 text-xs text-left">Bemerkungen: inkl. Mittagessen</p></Card>
<Card className="" id="liestal">
                     <p className="font-semibold text-xl">Liestal</p><p className="mt-4">NA, 08.45 - 12.30 Uhr</p><p className="mt-0 mb-4">Baumschule Heinis, Liestal</p><p className="mt-2 text-xs text-left">Kontakt: Simone Avila, <a href="tel:077 403 00 14">077 403 00 14</a>, <a href="mailto:simone.avila@nvliestal.ch">simone.avila@nvliestal.ch</a></p><p className="mt-2 text-xs text-left">Veranstalter: NV Liestal</p><p className="mt-2 text-xs text-left">Arbeiten: Steinhaufen bauen, Nistkästen aufhängen, Kopfweiden schneiden</p><p className="mt-2 text-xs text-left">Bemerkungen: Bitte Gartenhandschuhe und Gartenschere mitbringen.Verpflegung vorhanden.</p></Card>
<Card className="" id="münchenstein">
                     <p className="font-semibold text-xl">Münchenstein</p><p className="mt-4">4. November 2023, 09.00 - 13.00 Uhr</p><p className="mt-0 mb-4">Werkhof Münchenstein, Pumpwerkstrasse</p><p className="mt-2 text-xs text-left">Kontakt: Benjamin Kobler, <a href="tel:061 416 13 17">061 416 13 17</a>, <a href="mailto:benjamin.kobler@muenchenstein.ch">benjamin.kobler@muenchenstein.ch</a></p><p className="mt-2 text-xs text-left">Veranstalter: Einwohnergemeinde</p><p className="mt-2 text-xs text-left">Weitere Beteiligte: Forst, Werkhof, NVVM, Jäger, Fischer</p><p className="mt-2 text-xs text-left">Arbeiten: Pflegearbeiten in der Natur</p><p className="mt-2 text-xs text-left">Bemerkungen: Im Anschluss wird ein Mittagessen offeriert</p></Card>
<Card className="" id="oberwil">
                     <p className="font-semibold text-xl">Oberwil</p><p className="mt-4">21. Oktober 2023, 10.00 - 16.00 Uhr</p><p className="mt-0 mb-4">Feuerstelle «Allmen», Neuwilerstrasse, 4104 Oberwil</p><p className="mt-2 text-xs text-left">Kontakt: Roland Steiner, <a href="tel:061 401 09 57">061 401 09 57</a>, <a href="mailto:roland.steiner@nvoberwil.ch">roland.steiner@nvoberwil.ch</a></p><p className="mt-2 text-xs text-left">Veranstalter: Natur- und Vogelschutzverein Oberwil</p><p className="mt-2 text-xs text-left">Arbeiten: Wir schneiden schnellwachsende Sträucher und Bäume zurück und
bauen Kleinstrukturen aus dem Schnittmaterial. Ebenfalls braucht die Wieselburg eine Auffrischung aus Astmaterial.</p><p className="mt-2 text-xs text-left">Bemerkungen: Kinder sind herzlich willkommen</p></Card>
<Card className="" id="ormalingen">
                     <p className="font-semibold text-xl">Ormalingen</p><p className="mt-4">28. Oktober 2023, 08.30 - 13.00 Uhr</p><p className="mt-0 mb-4">Turnhalle Ormalingen</p><p className="mt-2 text-xs text-left">Kontakt: Denise Bussinger, <a href="tel:078 753 67 41">078 753 67 41</a>, <a href="mailto:nvvormalingen@gmx.ch">nvvormalingen@gmx.ch</a></p><p className="mt-2 text-xs text-left">Veranstalter: Natur- und Vogelschutzverein Ormalingen</p><p className="mt-2 text-xs text-left">Arbeiten: diverse Arbeiten im Naturschutzgebiet Bodenmatt</p></Card>
<Card className="" id="pratteln">
                     <p className="font-semibold text-xl">Pratteln</p><p className="mt-4">21. Oktober 2023, 08.30 - 12.00 Uhr</p><p className="mt-0 mb-4">Lilienhofweg 21</p><p className="mt-2 text-xs text-left">Kontakt: Martin Classen, <a href="tel:061 825 23 17">061 825 23 17</a>, <a href="mailto:martin.classen@pratteln.ch">martin.classen@pratteln.ch</a></p><p className="mt-2 text-xs text-left">Veranstalter: Naturschutzkommission</p><p className="mt-2 text-xs text-left">Weitere Beteiligte: Werkhof, NVVP</p><p className="mt-2 text-xs text-left">Arbeiten: Pflanzung von Bäumen, Sträucher und neue Asthaufen.</p><p className="mt-2 text-xs text-left">Bemerkungen: Der Brunnmeister zeigt uns die Quellfassung vor Ort. Und ein feines Znüni gibt es auch noch.</p></Card>
<Card className="" id="ramlinsburg">
                     <p className="font-semibold text-xl">Ramlinsburg</p><p className="mt-4">28. Oktober 2023, 09.00 - 12.00 Uhr</p><p className="mt-0 mb-4">Mehrzeckhalle Ramlinsburg, Brünneliweg 9</p><p className="mt-2 text-xs text-left">Kontakt: Doreth Strübin, <a href="tel:079 412 06 12">079 412 06 12</a>, <a href="mailto:doreth.struebin@gmail.com">doreth.struebin@gmail.com</a></p><p className="mt-2 text-xs text-left">Veranstalter: Verein für Naturschutz Ramlinsburg</p><p className="mt-2 text-xs text-left">Arbeiten: Looch, Bodenrüti und Buecherain: Scnittmaterial auf Haufen schichten</p><p className="mt-2 text-xs text-left">Bemerkungen: Nach dem Arbeitseinsatz wird im Vereinsraum ein Mittagessen offeriert.</p></Card>
<Card className="" id="reinach">
                     <p className="font-semibold text-xl">Reinach</p><p className="mt-4">28. Oktober 2023, 09.00 – 12.00 Uhr</p><p className="mt-0 mb-4">Fussballplatz Fiechten Eingang West</p><p className="mt-2 text-xs text-left">Kontakt: Fabio Di Pietro, <a href="tel:061 712 55 06">061 712 55 06</a>, <a href="mailto:info@vnvr.ch">info@vnvr.ch</a></p><p className="mt-2 text-xs text-left">Veranstalter: Verein für Natur- und Vogelschutz Reinach</p><p className="mt-2 text-xs text-left">Weitere Beteiligte: Einwohnergemeinde Reinach, MerNatur, Neuhof Reinach</p><p className="mt-2 text-xs text-left">Arbeiten: Hecken pflanzen und Kleinstrukturen bauen</p><p className="mt-2 text-xs text-left">Bemerkungen: <strong>Anmeldung</strong> erwünscht. Gartenkleider und Gartenhandschuhe mitnehmen. Gemeinsames Mittagessen. Auch Kinder sind herzlich willkommen. <a href="https://www.vnvr.ch/aktivit%C3%A4ten-1/29-naturschutztag/">https://www.vnvr.ch/aktivit%C3%A4ten-1/29-naturschutztag/</a></p></Card>
<Card className="" id="rickenbach bl">
                     <p className="font-semibold text-xl">Rickenbach BL</p><p className="mt-4">21. Oktober 2023, 13.00 - 17.00 Uhr</p><p className="mt-0 mb-4">Friedhofsparkplatz Rickenbach BL</p><p className="mt-2 text-xs text-left">Kontakt: Mathias Oberer§, <a href="tel:079 383 55 16">079 383 55 16</a>, <a href="mailto:mathias.oberer@outlook.com">mathias.oberer@outlook.com</a></p><p className="mt-2 text-xs text-left">Veranstalter: Natur in Rickenbach</p><p className="mt-2 text-xs text-left">Arbeiten: Pflegearbeiten an Hecke, im Steinbruch und Neophytenbekämpfung</p><p className="mt-2 text-xs text-left">Bemerkungen: Zum Abschluss offeriert NiR ein Zvieri</p></Card>
<Card className="" id="rothenfluh">
                     <p className="font-semibold text-xl">Rothenfluh</p><p className="mt-4">4. November 2023, 13.15 - 17.00 Uhr</p><p className="mt-0 mb-4">im 'Feldschen'</p><p className="mt-2 text-xs text-left">Kontakt: Bruno Erny, <a href="tel:079 592 16 04">079 592 16 04</a>, <a href="mailto:bruno.erny@breitband.ch">bruno.erny@breitband.ch</a></p><p className="mt-2 text-xs text-left">Veranstalter: Natur-und Vogelschutzverein NUVRAO & Familie Mumenthaler</p><p className="mt-2 text-xs text-left">Weitere Beteiligte: Landwirtschaftsbetrieb Mumenthaler, Hof Säge</p><p className="mt-2 text-xs text-left">Arbeiten: Pflanzung von 14 Feldbäumen</p><p className="mt-2 text-xs text-left">Bemerkungen: weitere Infos: www.nuvrao.ch</p></Card>
<Card className="" id="rünenberg">
                     <p className="font-semibold text-xl">Rünenberg</p><p className="mt-4">28. Oktober 2023, 09.00 - 13.00 Uhr</p><p className="mt-0 mb-4">Werkhof</p><p className="mt-2 text-xs text-left">Kontakt: Thomas Zumbrunn, <a href="tel:079 776 35 73">079 776 35 73</a>, <a href="mailto:thomas@zumbrunn.me">thomas@zumbrunn.me</a></p><p className="mt-2 text-xs text-left">Veranstalter: Natur am Wisenberg</p><p className="mt-2 text-xs text-left">Weitere Beteiligte: Jagdgesellschaft Rünenberg-Kilchberg, Forstrevier Homburg, Einwohner- und Bürgergemeinde Rünenberg</p><p className="mt-2 text-xs text-left">Arbeiten: Pflanzung Hochstamm-Obstbäume, Pflege Chrindelmatte, Pflege lichter Wald</p></Card>
<Card className="" id="sissach">
                     <p className="font-semibold text-xl">Sissach</p><p className="mt-4">28. Oktober 2023, 09.00 - 12.00 Uhr</p><p className="mt-0 mb-4">Scheunen oberhalb Schrebergärten, Gabelung Alpbad und Lomberg</p><p className="mt-2 text-xs text-left">Kontakt: Daniel Schmuzz, <a href="tel:061 971 74 66">061 971 74 66</a>, <a href="mailto:daniel.schmutz@bluewin.ch">daniel.schmutz@bluewin.ch</a></p><p className="mt-2 text-xs text-left">Veranstalter: AGNHS + Kommission Landschaft</p><p className="mt-2 text-xs text-left">Arbeiten: Bäume und Sträucher pflanzen, alten Maschendrahtzaun aus Waldrand entfernen</p></Card>
<Card className="" id="thürnen">
                     <p className="font-semibold text-xl">Thürnen</p><p className="mt-4">25. November 2023, 08.00 - 13.00 Uhr</p><p className="mt-0 mb-4">Beim Brunnen in der Rebgasse (beim Altersheim)</p><p className="mt-2 text-xs text-left">Kontakt: Mario Flückiger, <a href="tel:079 607 07 30">079 607 07 30</a>, <a href="mailto:mario.flueckiger@eblcom.ch">mario.flueckiger@eblcom.ch</a></p><p className="mt-2 text-xs text-left">Veranstalter: Bügerkollegium (Büko) Thürnen</p><p className="mt-2 text-xs text-left">Arbeiten: Waldputz</p></Card>
<Card className="" id="titterten">
                     <p className="font-semibold text-xl">Titterten</p><p className="mt-4">28. Oktober 2023, 09.00 - 16.00 Uhr</p><p className="mt-0 mb-4">Gemeindehaus</p><p className="mt-2 text-xs text-left">Kontakt: Enrico Leuzinger, <a href="tel:079 966 68 57">079 966 68 57</a>, <a href="mailto:enrico.leuzinger@kit.edu">enrico.leuzinger@kit.edu</a></p><p className="mt-2 text-xs text-left">Veranstalter: NVVT</p><p className="mt-2 text-xs text-left">Arbeiten: Wieselbauten aus Stein</p></Card>
<Card className="" id="wenslingen">
                     <p className="font-semibold text-xl">Wenslingen</p><p className="mt-4">28. Oktober 2023, 09.00 - 13.00 Uhr</p><p className="mt-0 mb-4">Dorfladen Wenslingen</p><p className="mt-2 text-xs text-left">Kontakt: Regula Waldner, <a href="tel:061 991 99 58">061 991 99 58</a>, <a href="mailto:r.waldner@vtxmail.ch">r.waldner@vtxmail.ch</a></p><p className="mt-2 text-xs text-left">Veranstalter: Naturschutzverein</p><p className="mt-2 text-xs text-left">Arbeiten: Weiher putzen, Schwalbennester putzen, Kopfweidenpflege</p><p className="mt-2 text-xs text-left">Bemerkungen: bitte Aushang beim Laden beachten</p></Card>
<Card className="" id="zeglingen und kilchberg">
                     <p className="font-semibold text-xl">Zeglingen und Kilchberg</p><p className="mt-4">14. Oktober 2023, 09.00 - 13.00 Uhr</p><p className="mt-0 mb-4">Garagenhalle Gipsi</p><p className="mt-2 text-xs text-left">Kontakt: Urs Wolfsberger, <a href="tel:079 470 71 33">079 470 71 33</a>, <a href="mailto:urs.wolfsberger@gmail.com">urs.wolfsberger@gmail.com</a></p><p className="mt-2 text-xs text-left">Veranstalter: Natur am Wisenberg</p><p className="mt-2 text-xs text-left">Weitere Beteiligte: Jagdgesellschaft Altschloss Zeglingen, Einwohner- und Bürgergemeinden Zeglingen und Kilchberg</p><p className="mt-2 text-xs text-left">Arbeiten: Aufräumarbeiten & Unterhalt Naturlehrpfad in der Gipsgrube, Waldrandpflege in Kilchberg</p><p className="mt-2 text-xs text-left">Bemerkungen: Für alle Helferinnen und Helfer wird von der Bürgergemeinde Kilchberg ein Znüni und von der
Bürgergemeinde Zeglingen ein Mittagessen offeriert.</p></Card> */}

        </div>
      </div>
</section>

    <section id="melden" className="container mx-auto my-20 py-20 bg-gray-200 rounded-lg text-center">
      <h3 className="text-5xl font-semibold">Anlass melden</h3>
      <p className="mt-8 text-xl font-light">
        Bitte benutzen Sie das Formular, um Ihren geplanten Anlass oder Änderungen zu melden:
      </p>
       <p className="mt-8">
        <Button size="xl" onclick="window.location.href = 'https://form.jotform.com/222343551424347';">zum Formular</Button>
      </p> 

      
    </section> 
  </Layout>
);

export default Index;
